import React, {useState,useEffect} from "react";
import { Link } from "gatsby"
import { connect } from "react-redux";

import Formulario from '../Contact.jsx'

import {getPrices, getRooms, getRealAddress, getEnvironment,getSurfaces, hasGarage, makeLink} from '../../helpers/helper.properties'
import {group_Unities} from '../../helpers/helper.developments'

import Loading from '../../components/Loading'


const Units = (props) => {

    const [group,setGroup] = useState([])
    const [load,setLoad] = useState(false)

    const {unities} = props
    const {dispatch} = props
    const {development} = props
    const {loading} = props

    const [filters,setFilters] = useState(0)
    const [isArrowClicked, setIsArrowClicked] = useState(false)

    useEffect(() => {
        if(unities){
            setGroup(group_Unities(unities,filters))
            setLoad(true)
        }
    },[unities,filters])


    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    // Filter state to show buttons
    const [buttonsFilters, setButtonsFilters] = useState([])

    // Use Effect to set buttons array only
    useEffect(() => {
        // if unities and group are loadead
        if(unities && group) {
            // and the state array is 0
            if(buttonsFilters.length === 0)
                setButtonsFilters(groupFilter(group))
        }
    },[unities, group])

    // Helper function to filter
    const groupFilter = (group) => {
        // first take the unities
        let filteredUnities = [];
        for (let i = 0; i < group.length; i++) {
            filteredUnities.push(...group[i].unities);
        }
        // and then take rooms
        let filteredUnitiesByRooms = [];
        for (let fu of filteredUnities) {
            if (!filteredUnitiesByRooms.includes(fu.rooms))
                filteredUnitiesByRooms.push(fu.rooms);
        }
        return filteredUnitiesByRooms;
    }

    return !loading ? (
        <section class={"ficha-units "}>
            <div class="container-fluid">
                {( group.length > 0 || filters > 0? 
                <>
                    <div class="row intro">
                        <div class="col-lg-2">
                            <h2 class="section-name">Unidades</h2>
                        </div>
                        <div class="col-lg-10">
                            <p>Gran variedad de unidades que se adaptan <br class="d-none d-lg-block" /> a todas tus necesidades y requerimientos de búsqueda.</p>
                        </div>
                        <div className="col-12">
                            <div className="d-flex flex-wrap justify-content-center mt-5">
                                {/* Button filter array */}
                                { buttonsFilters.sort((a,b)=>a-b).map((item, index) => 
                                    <div key={index} 
                                         onClick={() => updateFilter(item)}
                                         className={(filters === item ? ' active ' : '')}> 
                                        <div className={"tag-filter proyect-bg me-3 mb-3" + (filters === item ? ' active ' : '')}>
                                            { item === 1 ? 'Monoambiente' : (item <= 5 ? `${item} ambientes` : '+') }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>    
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            {group && load ? 
                                group.map((item,index) => (
                                    <div className={"unity collapsed"} data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                                        {/* <div class="row unit-table text-center no-gutters justify-content-lg-between">
                                                <p class="col-lg-2 col-4 ps-0 pe-lg-2 pe-xl-0 text-center">Unidad</p>
                                                <p class="col-lg-1 col-3">Ambientes</p>
                                                <div class="col-lg-8 col-3"> */}
                                                {/* </div>
                                                <p class="col-lg-1 col-2"></p>
                                            </div> */}
                                        <div className="head-row row">
                                            <div className="col-lg-2 col-3">
                                                <span className="unity-font">{item.floor}</span>
                                            </div>
                                            <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
                                            </div>
                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. cubierta </span>
                                            </div>
                                            <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. Total </span>
                                            </div>
                                            <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Precio </span>
                                            </div>
                                            <div className="col-lg-1 col-2" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                            <i className={'icon-arrow-right'}></i>
                                        </div>
                                        </div>
                                        <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                            {/* {item.unities.sort(function(a, b){return a.address.split(" ")[1] - b.address.split(" ")[1]}).map((element,index) => ( // NO ANDA??                            (element.status === 2 ? */}
                                            {item.unities.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                         
                                                (element.status === 2 ?
                                                <>
                                                    <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}>
                                                        <div className={"unity-row unit row " + (element.status !== 2 ? 'disabled' : '')} >
                                                            <div className="col-lg-2 col-3">
                                                                <span className={"unity-yellow-font montserrat-regular proyect-color"}>{element.address}</span>
                                                            </div>
                                                            <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                            </div>
                                                            {/* <div className="col-2 col-lg-1 round"><Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id} className="icon-arrow-right"></Link></div> */}
                                                        </div>
                                                    </Link>
                                                </>
                                            :
                                                <>
                                                    <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}>
                                                        <div className={"unity-row unit row disabled"} >
                                                            <div className="col-lg-6 col-3">
                                                                <span className={"unity-yellow-font proyect-color"}>{element.address}  </span>
                                                            </div>
                                                            <div className="col-lg-2 col-3" style={{textAlign:'center',transform:"rotate(0deg)"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                            </div>
                                                            <div className="col-lg-3 col-4" style={{textAlign:"center"}}>
                                                                <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                            </div>
                                                            {/* <div className="col-2 col-lg-1 round"><Link to="" className="icon-arrow-right"></Link></div> */}
                                                        </div>
                                                    </Link>
                                                </>
                                            )
                                        ))}
                                    </div>
                                        {/* <div class="row text-center justify-content-between">
                                            {unities.map((unity,index) => (
                                                <div class="col-12">
                                                    <a style={{display:"contents"}} href="">
                                                        <div class="unit">
                                                            <div class="row text-center no-gutters justify-content-lg-between align-items-center">
                                                                <p class="col-lg-2 col-4 ps-lg-4 ps-lg-auto red text-start">{getRealAddress(unity)}</p>
                                                                <p class="col-lg-1 col-3">{getEnvironment(unity)}</p>
                                                                <div class="col-lg-8 col-3 px-lg-0">
                                                                    <div class="row align-items-center">
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{getRooms(unity)}</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{getSurfaces(unity).roofed}</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{getSurfaces(unity).total_surface}</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline">{hasGarage(unity) ? 'Si' : 'No'}</p>
                                                                        <p class="text-center col-lg-2 ">{getPrices(unity)[0].currency + " " + Intl.NumberFormat("de-DE").format(getPrices(unity)[0].price)}</p>
                                                                        <p class="text-center col-lg-2 ">-</p>
                                                                        <p class="text-center col-lg-2 d-none d-lg-inline"><?php if(isset($prop_data['price']['Alquiler']) ){ echo $prop_data['price']['Alquiler']; }else{ echo '-' ;} ?></p>
                                                                    </div>
                                                                </div>
                                                                <p class="round col-lg-1 col-2"><Link to={makeLink(unity)} className="icon-arrow-right"></Link></p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div> */}
                                    </div>
                                ))
                            :''
                            }
                            <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                                <p className="disabled-unities text-center" style={{fontSize:"1rem",margin: "0rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                            </div>
                        </div>
                    </div>  
                </>

                :'')}
                <div class="row contacto-main">
                    <div class="col-lg-6 col-12 intro">
                        <h2 class="mb-4">Consultanos <br class="d-none d-lg-inline-block" /> por este emprendimiento </h2>
                        <p class="mt-lg-5">
                            Si ya tenés una propiedad en mente, no dudes<br /> 
                            en consultarnos. Con gusto responderemos<br /> 
                            todas tus dudas y te guiaremos durante
                            el proceso.
                        </p>
                    </div>
                    <div class="col-lg-6 col-12">
                        <Formulario development={development} from={'development'} />
                        <div id="alert-message"></div>
                    </div>
                </div>
            </div>

            
        </section>

    ):
    <>
      <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    unities: state.developments.unities,
    development: state.developments.development,
    loading: state.developments.loading,
}),null)(Units);