export const getCountUnities = (unities) => {
    if(unities.length > 0){
        return unities.length
    }
}
export const getMinAndMaxPrice = (unities) => {
    const prices = unities.map(unity => unity.operations[0].prices[0].price)
    let min = 0
    let max = 0
    min = Math.min(...prices)
    max = Math.max(...prices)
    return {min, max}
}
