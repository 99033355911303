import React, {useEffect} from "react";
import Seo from "../components/seo"
import Layout from "../components/layout"

import About from '../components/Emprendimiento/about'
import Units from '../components/Emprendimiento/units'

import { connect } from "react-redux"

import { useParams } from "@reach/router"
import {getDevelopmentAction, getUnitsDevelopmentAction} from '../redux/developmentsDucks'



const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Emprendimiento/main")
)

const Development = ({loading,loading_unities,api_key,dispatch}) => {

  const {id} = useParams()

  useEffect(() => {
    if(api_key){
      dispatch(getDevelopmentAction(id))
      dispatch(getUnitsDevelopmentAction(id))
    }
  },[api_key])


  const isSSR = typeof window === "undefined"

	return (
        <Layout>
            <Seo title="Emprendimiento" />
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy />
              </React.Suspense>
            )}
            <About />
            <Units />
        </Layout> 
    )
}
export default connect(state => ({
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko
}),null)(Development);